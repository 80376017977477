import IMAGES from "./images";

export const features = [
  {
    id: 1,
    icon: IMAGES.PLATFORMS_STATISTICS,
    title: "platforms_statistics",
    description: "platforms_statistics_desc",
  },

  {
    id: 5,
    icon: IMAGES.APPLICATION_UPDATE,
    title: "unlimited_application_update",
    description: "unlimited_application_update_desc",
  },

  {
    id: 2,
    icon: IMAGES.BILLING_HISTORY,
    title: "billing_history",
    description: "billing_history_desc",
  },

  {
    id: 6,
    icon: IMAGES.SUPPORT,
    title: "support",
    description: "support_desc",
  },

  {
    id: 3,
    icon: IMAGES.REBRENDING,
    title: "application_rebranding",
    description: "application_rebranding_desc",
  },

  {
    id: 7,
    icon: IMAGES.NOTIFICATION,
    title: "notification",
    description: "notification_desc",
  },

  {
    id: 4,
    icon: IMAGES.UPGRADE,
    title: "upgrade_in_seconds",
    description: "upgrade_in_seconds_desc",
  },

  {
    id: 8,
    icon: IMAGES.TRANSLATE,
    title: "translate",
    description: "translate_desc",
  },
];

export const clientAppFeatures = [
  {
    id: 1,
    icon: IMAGES.FEATURE_SLIDE1,
    title: "live_tv_and_vod",
    description: "live_tv_and_vod_desc",
  },
  {
    id: 2,
    icon: IMAGES.FEATURE_SLIDE3,
    title: "translation",
    description: "translation_desc",
  },
  {
    id: 3,
    icon: IMAGES.FEATURE_SLIDE2,
    title: "add_favorites",
    description: "add_favorites_desc",
  },
  {
    id: 4,
    icon: IMAGES.FEATURE_SLIDE4,
    title: "continue_watching",
    description: "continue_watching_desc",
  },

  {
    id: 5,
    icon: IMAGES.FEATURE_SLIDE3,
    title: "translation",
    description: "translation_desc",
  },
  {
    id: 6,
    icon: IMAGES.FEATURE_SLIDE4,
    title: "continue_watching",
    description: "continue_watching_desc",
  },
];

export const partnerShipPoints = [
  {
    id: 0,
    icon: IMAGES.PARTNER,
    title: "subreselling_program",
  },
  {
    id: 1,
    icon: IMAGES.CREDITS,
    title: "customised_activation_count",
  },
  {
    id: 2,
    icon: IMAGES.PRICES_PARTNER,
    title: "flexible_discount_options",
  },
  {
    id: 3,
    icon: IMAGES.PREMIUM_PARTNER,
    title: "premium_support",
  },
  {
    id: 4,
    icon: IMAGES.BRANDED_PARTNER,
    title: "own_branded_app",
  },
  {
    id: 4,
    icon: IMAGES.MIGRATION_PARTNER,
    title: "easy_migration_system",
  },
];

export const pricingCard = [
  {
    id: 0,
    title: "X-Starter",
    price: 49,
    devices: 3,
  },
  {
    id: 1,
    title: "X-Starter",
    price: 49,
    devices: 3,
  },
  {
    id: 2,
    title: "X-Starter",
    price: 49,
    devices: 3,
  },
];
